import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

export const ContactUsWhatsApp = () => {
  return (
    <Button
      style={{ backgroundColor: "black", color: "white" }}
      target={"_blank"}
      rel="noferrer"
      href={`https://api.whatsapp.com/send/?phone=94777994411&text=I am interest in listing a property`}
    >
      <PlusOutlined style={{ color: "white", verticalAlign: "baseline" }} />
      {"List my property"}
    </Button>
  );
};
