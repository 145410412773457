export const propertyData = [
  {
    id: "0",
    available: true,
    thumbnailUrl:
      "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-ogImage-01.jpg",
    url: "marine-house",
    contactWhatsApp: "447723772377",
    contactNumber: "+94-777-322-500",
    title: "Marine House",
    description:
      "The property is conviently located close to the libery plaza roundabout. The building has a gross area of 25,000sqft across 8 floors and a typical floor has 3200sqft + 21 car parking spaces. ",
    ogDescription:
      "Marine House Building, located near the Liberty Plaza roundabout, 3200sqft per floor, 7 storeys with 15 parking spaces   ",
    type: "Office",
    bathrooms: "",
    bedrooms: "",
    floor: 8,
    area: "3200+",
    commericalTerms: [
      { id: 1, cat: "Deposit", text: "6months" },
      { id: 2, cat: "Advance", text: "3months" },
      { id: 3, cat: "Notice Period", text: "3months" },
      { id: 3, cat: "Billed", text: "billed seperately" },
    ],
    lettingDetails: [
      { id: 1, cat: "Avaliable", text: "Immediately" },
      { id: 2, cat: "Type of Let", text: "Long Term" },
      { id: 3, cat: "Furnished", text: "No" },
    ],
    keyFeatures: [
      { id: 1, cat: "", text: "Backup Generator" },
      { id: 2, cat: "", text: "24 Hour Security Guard" },
      { id: 3, cat: "", text: "CCTV" },
      { id: 4, cat: "", text: "Parking" },
      { id: 5, cat: "", text: "Each floor 3200sqft" },
      { id: 6, cat: "", text: "Anchor Tenant - Acquity Partners" },
    ],
    documents: [
      {
        id: 1,
        docUrl:
          "https://www.dropbox.com/sh/420onycsmql0443/AACnSiAa6C0xiInbEC15PoRJa?dl=0",
        title: "Image Folder",
      },
      {
        id: 2,
        docUrl:
          "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/floor-plans/1st+Floor+Aquity+Buiding+(1).pdf",
        title: "1st floor plan - PDF",
      },
      {
        id: 3,
        docUrl:
          "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/floor-plans/2nd+Floor+Aquity+Buiding.pdf",
        title: "2nd floor plan - PDF",
      },
      {
        id: 4,
        docUrl:
          "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/floor-plans/Ground+floor+Aquity+Buiding+Plan-Model.pdf",
        title: "ground floor plan - PDF",
      },
    ],
    askingPrice: 180,
    parkingSpaces: 12,
    elevator: true,
    generator: true,
    video: "https://vimeo.com/showcase/10251615/embed",

    location: "No.53, Dharmapala Mawatha, Colombo 03, Kollupitya",
    propertyPdf: "",
    images: [
      {
        key: "1",
        alt: "street view of building",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-building.jpg",
      },
      {
        key: "2",
        alt: "1st floor view",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-1st-floor.jpg",
      },
      {
        key: "3",
        alt: "floor plan",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-building-floor-plan-gallery.jpg",
      },
      {
        key: "4",
        alt: "3rd floor view",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acquity-3rd-floor-02.jpg",
      },
      {
        key: "5",
        alt: "3rd floor view",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acquity-3rd-floor.jpg",
      },
      {
        key: "6",
        alt: "ground floor car park",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-building-ground-floor.jpg",
      },
    ],
    matterport: "https://my.matterport.com/show/?m=68PkGc3wsQY",
    googleMapPin:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2954.3987557744076!2d79.85106139451936!3d6.912422157015676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25969e9f455ed%3A0xcf1a369fdc92547c!2sAcuity%20House%2C%2053%20Srimath%20Anagarika%20Dharmapala%20Mawatha%2C%20Colombo%2000300%2C%20Sri%20Lanka!5e0!3m2!1sen!2sjo!4v1678628199379!5m2!1sen!2sjo",
  },
  {
    id: "1",
    available: true,
    contactWhatsApp: "447723772377",
    thumbnailUrl:
      "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png",
    title: "Warehouse No1",
    description:
      "kollupitiya roundabout, 7 floor office building with parking, each floor has 2100sqft ",
    type: "Warehouse",
    bathrooms: "",
    bedrooms: "",
    floor: "",
    area: "10,000 + sqft",
    commericalTerms: [
      { id: 1, cat: "Deposit", text: "3months" },
      { id: 2, cat: "Advance", text: "3months" },
      { id: 3, cat: "Notice Period", text: "3months" },
      { id: 3, cat: "Billed", text: "billed seperately" },
    ],
    lettingDetails: [
      { id: 1, cat: "Avaliable", text: "Immediately" },
      { id: 2, cat: "Type of Let", text: "Long Term" },
      { id: 3, cat: "Furnished", text: "No" },
    ],
    keyFeatures: [
      { id: 1, cat: "", text: "Backup Generator" },
      { id: 2, cat: "", text: "24 Hour Security Guard" },
      { id: 3, cat: "", text: "CCTV" },
      { id: 4, cat: "", text: "Parking" },
      { id: 5, cat: "", text: "Each floor 10,00sqft" },
    ],
    documents: [{ id: 1, docUrl: "https://google.com", title: "Gas Satefy" }],
    askingPrice: 180,
    parkingSpaces: 12,
    elevator: true,
    generator: true,
    video: "",
    location: "Peliyagoda",
    images: [
      {
        key: "1",
        alt: "street view of building",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-building.jpg",
      },
    ],
    matterport: false,
    googleMapPin:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.393780527649!2d79.88893249610538!3d6.962788097859579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25924ee25ac87%3A0xd10acf6b8d914d45!2sEast%20West%20Properties%20PLC!5e0!3m2!1sen!2sjo!4v1678628371161!5m2!1sen!2sjo",
  },
  {
    id: "2",
    available: true,
    contactWhatsApp: "447723772377",
    contactNumber: "+94-777-322-500",
    thumbnailUrl:
      "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png",
    title: "Warehouse No2",
    description: "This is the description",
    type: "Office",
    floor: 5,
    askingPrice: 180,

    images: [
      {
        key: "1",
        alt: "street view of building",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-building.jpg",
      },
    ],
    video: "",
    commericalTerms: [{ id: 0, cat: "", text: "" }],
    lettingDetails: [{ id: 0, cat: "", text: "" }],
    keyFeatures: [{ id: 0, cat: "", text: "" }],
    documents: [
      {
        id: 1,
        docUrl: "",
        title: "",
      },
    ],

    parkingSpaces: 12,
    elevator: true,
    generator: true,
    location: "Peliyagoda",
    matterport: false,
    googleMapPin:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.393780527649!2d79.88893249610538!3d6.962788097859579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25924ee25ac87%3A0xd10acf6b8d914d45!2sEast%20West%20Properties%20PLC!5e0!3m2!1sen!2sjo!4v1678628371161!5m2!1sen!2sjo",
  },
  {
    id: 3,
    available: true,
    contactWhatsApp: "447723772377",
    contactNumber: "+44-77-2377-2377",
    thumbnailUrl:
      "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png",
    title: "Warehouse No3",
    description: "This is the description",
    type: "Office",
    floor: 5,
    askingPrice: 180,
    commericalTerms: [{ id: 0, cat: "", text: "" }],

    parkingSpaces: 12,
    elevator: true,
    generator: true,
    video: "",
    location: "Peliyagoda",
    images: [
      {
        key: "1",
        alt: "street view of building",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-building.jpg",
      },
    ],
    matterport: false,
    googleMapPin:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.393780527649!2d79.88893249610538!3d6.962788097859579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25924ee25ac87%3A0xd10acf6b8d914d45!2sEast%20West%20Properties%20PLC!5e0!3m2!1sen!2sjo!4v1678628371161!5m2!1sen!2sjo",
  },
  {
    id: 4,
    url: "crescat-123c",
    contactWhatsApp: "447723772377",
    contactNumber: "+44-77-2377-2377",
    available: true,
    thumbnailUrl:
      "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/examples/ogImage-pages-crescat-12-3C-01.jpg",
    title: "Crescat Residencies - 1 bedroom",
    description: "Crescat Residencies, 1 bedroom, 1 bathroom, 780sqft ",
    type: "Apartment",
    bedrooms: 1,
    bathrooms: 1,
    area: 898,
    floor: 0,
    askingPrice: 180000,
    commericalTerms: [
      { id: 1, cat: "Deposit", text: "6months" },
      { id: 2, cat: "Advance", text: "3months" },
      { id: 3, cat: "Notice Period", text: "3months" },
      { id: 4, cat: "Utilities", text: "billed seperately" },
      { id: 5, cat: "Avaliable", text: "Immediately" },
      { id: 6, cat: "Type of Let", text: "Long Term" },
      { id: 7, cat: "Furnished", text: "Yes" },
    ],
    lettingDetails: [
      { id: 1, cat: "Avaliable", text: "Immediately" },
      { id: 2, cat: "Type of Let", text: "Long Term" },
      { id: 3, cat: "Furnished", text: "Yes" },
    ],
    keyFeatures: [
      { id: 1, cat: "", text: "Gym" },
      { id: 2, cat: "", text: "Swimming" },
      { id: 3, cat: "", text: "Located next to Cinnamon Grand" },
      { id: 4, cat: "", text: "Parking" },
    ],
    documents: [
      {
        id: 1,
        docUrl:
          "https://www.dropbox.com/scl/fo/n0f82sz2j7rd26uqnvbyf/h?dl=0&rlkey=syysrseahvm249pv7em00s7fi",
        title: "Image Folder",
      },
    ],
    parkingSpaces: 0,
    elevator: true,
    generator: true,
    video: false,
    location: "Crescat Residencies, 75 Galle Road, Colombo 03",
    images: [
      {
        key: "2",
        alt: "living room",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/examples/crescat-12-3C/prop-images-12-3C-living-room-03.jpeg",
      },
      {
        key: "3",
        alt: "living room",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/examples/crescat-12-3C/prop-images-12-3C-living-room-03.jpg",
      },
      {
        key: "3",
        alt: "kitchen",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/examples/crescat-12-3C/prop-images-12-3C-kitchen-01.jpg",
      },
      {
        key: "4",
        alt: "bedroom",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/examples/crescat-12-3C/prop-images-12-3C-bedroom-01-01.jpeg",
      },
      {
        key: "5",
        alt: "balcony",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/examples/crescat-12-3C/prop-images-12-3C-balcony-02-01.jpeg",
      },
      {
        key: "6",
        alt: "balcony",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/examples/crescat-12-3C/prop-images-12-3C-balcony-01.jpeg",
      },
    ],
    matterport: "https://my.matterport.com/show/?m=UUfWsdvofQg",
    googleMapPin:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.7718620826827!2d79.84580391475814!3d6.917855595001566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25940eafda675%3A0xc000bf99084c0400!2sCrescat%20Residencies%2C%20Colombo%2C%20Sri%20Lanka!5e0!3m2!1sen!2sjo!4v1679844251496!5m2!1sen!2sjo",
  },
  {
    id: 5,
    url: "ew-warehouse-1",
    contactWhatsApp: "94777748423",
    contactNumber: "+94-7777-48-423",
    available: true,
    thumbnailUrl: "",
    title: "5,000 - 25,0000sqft Warehouse",
    description:
      "Located conviently off the Colombo - Katunayake Expressway E03 near to the Peliyagoda exit. The development has over 100,000sqft of rental space ",
    type: "Warehouse",
    floor: 0,
    bedrooms: false,
    bathrooms: false,
    area: "+10,000sqft",
    askingPrice: 180000,
    commericalTerms: [
      { id: 1, cat: "Deposit", text: "6months" },
      { id: 2, cat: "Advance", text: "3months" },
      { id: 3, cat: "Notice Period", text: "3months" },
      { id: 3, cat: "Billed", text: "billed seperately" },
    ],
    lettingDetails: [
      { id: 1, cat: "Avaliable", text: "Immediately" },
      { id: 2, cat: "Type of Let", text: "Long Term" },
    ],
    keyFeatures: [{ id: 1, cat: "", text: "" }],
    documents: [
      {
        id: 1,
        docUrl: "",
        title: "Image Folder",
      },
    ],
    parkingSpaces: 0,
    elevator: true,
    generator: true,
    video: "",
    location: "81/11 3rd Lane, New Nuge Road, Peliyagoda,",
    images: [
      {
        key: "1",
        alt: "street view of building",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/examples/ogImage-pages-ew-warehouse-1.jpg",
      },
    ],
    matterport: false,
    googleMapPin:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.393780527649!2d79.88893249610538!3d6.962788097859579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25924ee25ac87%3A0xd10acf6b8d914d45!2sEast%20West%20Properties%20PLC!5e0!3m2!1sen!2sjo!4v1678628371161!5m2!1sen!2sjo",
  },
  {
    id: 50000,
    url: "template",
    title: "",
    description: "",
    ogDescription: "",
    ogTitle: "",
    contactWhatsApp: "",
    contactNumber: "",
    location: "",
    available: true,
    thumbnailUrl: "",

    type: "",
    bedrooms: false,
    bathrooms: false,
    area: false,
    floor: 0,
    askingPrice: 180000,
    commericalTerms: [{ id: 1, cat: "", text: "" }],
    lettingDetails: [{ id: 1, cat: "", text: "" }],
    keyFeatures: [{ id: 1, cat: "", text: "" }],
    documents: [
      {
        id: 1,
        docUrl: "",
        title: "",
      },
    ],
    parkingSpaces: 0,
    elevator: true,
    generator: true,
    video: false,
    matterport: false,

    images: [
      {
        key: "1",
        alt: "street view of building",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-building.jpg",
      },
    ],
    googleMapPin:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.393780527649!2d79.88893249610538!3d6.962788097859579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25924ee25ac87%3A0xd10acf6b8d914d45!2sEast%20West%20Properties%20PLC!5e0!3m2!1sen!2sjo!4v1678628371161!5m2!1sen!2sjo",
  },
  {
    id: 50001,
    url: "monarch-13b4",
    title: "Monarch Residences - 2 bedroom 2 bathroom",
    description: "RENT - USD1,000 AVAILABLE NOW",
    ogDescription: "RENT - USD1,000 AVAILABLE NOW",
    ogTitle: "RENT - Monarch Residences - 2 bedroom",
    contactWhatsApp: "94777637137",
    contactNumber: "+94777637137",
    location: "Monarch Apartments, 89 Galle Rd, Colombo 00300",
    available: true,
    thumbnailUrl:
      "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/properties/monarch/living-room.jpeg",

    type: "Apartment",
    bedrooms: 2,
    bathrooms: 2,
    area: 1350,
    floor: 0,
    askingPrice: "USD1000",
    commericalTerms: [
      { id: 1, cat: "Deposit", text: "2months" },
      { id: 2, cat: "Advance", text: "6months" },
      { id: 3, cat: "Notice Period", text: "3months" },
      { id: 4, cat: "Utilities", text: "billed seperately" },
      { id: 4, cat: "Service", text: "included" },
    ],
    lettingDetails: [
      { id: 5, cat: "Avaliable", text: "Immediately - 1st March 2024" },
      { id: 6, cat: "Type of Let", text: "Long Term" },
      { id: 7, cat: "Furnished", text: "Yes" },
    ],
    keyFeatures: [{ id: 1, cat: "", text: "" }],
    documents: [
      {
        id: 1,
        docUrl:
          "https://drive.google.com/drive/folders/1GDOZfT0Utx2-vhMF07ZHmFAro2U55AUe?usp=sharing",
        title: "Image Folder",
      },
    ],
    parkingSpaces: 1,
    elevator: true,
    generator: true,
    video: false,
    matterport: false,

    images: [
      {
        key: "1",
        alt: "bedroom-02",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/properties/monarch/bedroom-01.jpeg",
      },
      {
        key: "2",
        alt: "bedroom-01",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/properties/monarch/bedroom-02.jpeg",
      },
      {
        key: "3",
        alt: "living-room",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/properties/monarch/living-room.jpeg",
      },
      {
        key: "4",
        alt: "bathroom",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/properties/monarch/13-b4/bathroom.jpg",
      },
      {
        key: "5",
        alt: "kitchen",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/properties/monarch/13-b4/kitchen.jpg",
      },
      {
        key: "6",
        alt: "view",
        url: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/properties/monarch/13-b4/view.jpg",
      },
    ],
    googleMapPin:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15843.10834509404!2d79.8484987!3d6.9172327!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2599475936893%3A0x3740bade75ddb9cf!2sMonarch%20Apartments!5e0!3m2!1sen!2slk!4v1705917784176!5m2!1sen!2slk",
  },
];
