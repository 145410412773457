import { Card, Col, Row } from "antd";
import { Link } from "react-router-dom";

export const Examples = () => {
  const exampleData = [
    {
      title: "Marine House - Colombo 03",
      url: "/id/marine-house",
      alt: "marine house view from the road",
      src: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-ew-lk/acuity-building/acuity-ogImage-01.jpg",
      type: "Office",
      size: "3200+ sqft",
      location: "Kollupitya",
    },
    {
      title: "Crescat - Colombo 03",
      url: "/id/crescat-123c",
      alt: "view of the living room",
      src: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/examples/ogImage-pages-crescat-12-3C-01.jpg",
      type: "Apartment",
      size: "1 bedroom",
      location: "Crescat",
    },
    {
      title: "Warehouse - Peliyagoda",
      url: "/id/ew-warehouse-1",
      alt: "front the warehouse",
      src: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-pages/examples/ogImage-pages-ew-warehouse-1.jpg",
      type: "Warehouse",
      size: "10,000sqft",
      location: "Peliyagoda",
    },
  ];

  const listExamples = exampleData.map((data) => {
    return (
      <Col xs={24} sm={24} md={8} style={{ textAlign: "center" }}>
        <Link to={data.url}>
          <Card
            style={{ padding: "15px" }}
            hoverable
            cover={<img alt={data.alt} src={data.src} />}
            actions={[data.type, data.size, data.location]}
          >
            {data.title}
          </Card>
        </Link>
      </Col>
    );
  });
  return (
    <div
      style={{
        background: "rgb(225, 225, 219)",
        textAlign: "center",
        backgroundImage: `url(https://img.theculturetrip.com/wp-content/uploads/2017/10/6485862885_4cda9d26c3_b.jpg)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        maxWidth: "1200px",
        width: "100%",
        minHeight: "420px",
        padding: "25px",
      }}
      id="projects"
    >
      <div
        style={{
          color: "white",
          fontWeight: "700",
          fontSize: "1.5rem",
          textAlign: "left",
        }}
      >
        <span style={{ background: "black", padding: "5px" }}>
          PROPERTY PAGES....
        </span>
      </div>
      <br></br>
      <Row id="examples" gutter={[18, 16]}>
        {listExamples}
      </Row>
    </div>
  );
};
